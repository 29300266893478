/**
 * Sort by key
 * @param {*} sortKey
 */
 function sortByKey(sortKey: any, type = 'desc') {
  const sortTypeIsAsc = (type.toLocaleLowerCase() === 'asc') ? true : false;

  return (a: any, b: any) => {
      const tempA = (sortTypeIsAsc) ? a : b;
      const tempB = (sortTypeIsAsc) ? b : a;

      if (tempA[sortKey] < tempB[sortKey] || tempA < tempB) {
          return -1;
      }
      if (tempA[sortKey] > tempB[sortKey] || tempA > tempB) {
          return 1;
      }
      return 0;
  };
}

function sortByDate(sortKey: any, type = 'desc') {
  const sortTypeIsAsc = (type.toLocaleLowerCase() === 'asc') ? true : false;

  return (a: any, b: any) => {
      const tempA = (sortTypeIsAsc) ? a : b;
      const tempB = (sortTypeIsAsc) ? b : a;

      if (new Date(tempA[sortKey]) < new Date(tempB[sortKey])) {
          return -1;
      }
      if (new Date(tempA[sortKey]) > new Date(tempB[sortKey])) {
          return 1;
      }
      return 0;
  };
}

export {
  sortByKey,
  sortByDate
};