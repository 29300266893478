import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getPreferedListHeight } from '../utils/layout';
import chunkArray from '../utils/chunk-array';
import TouchAppTwoToneIcon from '@mui/icons-material/TouchAppTwoTone';
import { GeneralListItem } from '../types/backend';

interface GeneralListProps {
  items: GeneralListItem[]
}

export default function GeneralList(props: GeneralListProps) {
  const reOrganizedItems = chunkArray(props.items, 2)
  const listComponents = reOrganizedItems.map((group: GeneralListItem[], index: number) => {
    return(
      <ListItem key={`item-list-${index}`} sx={{margin:'auto'}}>
        {group.map((item: GeneralListItem, index: number) => {
          return <ListItemText key={`${item.name}-${index}`} sx={{textAlign:'center', width:'50%'}} primary={item.name} secondary={`${item.experience} - Years`} />
        })}
      </ListItem>
    )
  })

  return (
    <List sx={{ maxHeight: getPreferedListHeight(480), overflow: 'auto', width: '100%', borderRadius: '5px', paddingTop: '0px', paddingBottom: '0px' }} className='black-opacity-mask'>
      {listComponents}
    </List>
  );
}
