import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Modal } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React from 'react';
import { Project } from '../types/backend';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'auto',
  margin: 'auto',
  maxHeight: '90%',
  boxShadow: 24,
  p: 4,
} as const;
interface ProjectsProps {
  items: Project[]
}

interface GenerateCardProps {
  item: Project
}

function GenerateCard(props: GenerateCardProps) {
  const item = props.item;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="third-block" style={{margin:'auto'}}>
      <Card onClick={handleOpen} sx={{ width: '94%', marginLeft: '3%', marginRight: '3%', marginBottom: '5%'}}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            width="100%"
            image={item.imageUrl}
            alt="project image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Modal
        open={(open)}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{overflow:'auto'}}
      >
        <Box sx={style}>
          <CancelOutlinedIcon sx={{float: 'right'}} onClick={handleClose} />
          <h2 id="parent-modal-title">{item.name}</h2>
          <p id="parent-modal-description" className="basic-text">
            {item.description}
          </p>
          <div className='media-card'>
            {!item.videoUrl && <CardMedia
              component='img'
              width="100%"
              image={item.imageUrl}
              alt="project media"
            />}
            {item.videoUrl && <video style={{margin: 'auto', width: '100%'}} poster={item.imageUrl} controls>
              <source src={item.videoUrl} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>}
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default function Projects(props: ProjectsProps) {
  return (
    <div className="wrapper" style={{flexWrap: 'wrap', margin:'auto'}}>
      {
        props.items.map((item, index) => {
          return <GenerateCard key={`generated-card-${index}`} item={item}/>
        })
      }
    </div>
  );
}
