import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ApiIcon from '@mui/icons-material/Api';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import TerminalIcon from '@mui/icons-material/Terminal';
import Typography from '@mui/material/Typography';
import TouchAppTwoToneIcon from '@mui/icons-material/TouchAppTwoTone';
import { TimeFrame } from '../types/backend';

interface DotTimelineProps {
  items: TimeFrame[]
}

function getIcon(category: string){
  switch(category) {
    case 'architecture':
      return <ArchitectureIcon />
    case 'mobile':
      return <PhoneAndroidIcon />
    case 'school':
      return <SchoolIcon />
    case 'coding':
      return <TerminalIcon />
    case 'certification':
      return <HistoryEduIcon />
    case 'training':
      return <HistoryEduIcon />
    case 'devops':
      return <PrecisionManufacturingIcon />
    case 'management':
      return <Diversity3Icon />
    default:
      return <ApiIcon />
  }
}

export default function DotTimeline(props: DotTimelineProps) {
  const timelineItems = props.items.map((item, index) => {
    return(
      <TimelineItem key={`timeline-item-${index}`}>
        <TimelineOppositeContent color="text.secondary">
          {item.time_frame}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">{getIcon(item.category)}</TimelineDot>
          {(props.items.length - 1 !== index) && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography component="span">
            {item.name}
          </Typography>
          <Typography></Typography>
        </TimelineContent>
      </TimelineItem>
    )
  });

  return (
    <Timeline sx={{margin: '0px'}}>
      {timelineItems}
    </Timeline>
  );
}