import { useState, useEffect } from 'react';
import './App.scss';
import Main from './components/main';
import axios from 'axios';
import { Version } from './types/backend';
import { api } from './config';

function getAndAllDocs() {
  return axios.get(`${api[process.env.REACT_APP_STAGE!]}/get-docs`).then(result => {
    return result.data;
  });
}

function App() {
  const [projectsData, setProjectsData] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [education, setEducation] = useState([]);
  const [competence, setCompetence] = useState([]);
  const [mainDescription, setMainDescription] = useState([]);
  const [awards, setAwards] = useState([]);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    axios.get(`${api[process.env.REACT_APP_STAGE!]}/get-version`).then(versionResponse => {
      const data: Version = versionResponse.data;
      const version = data.version;

      try {
        const currentUrl = window.location.href;
        const host = window.location.host;
        const protocol = window.location.protocol;
        const versionValidationPath = `/${data.path}?v=${version}`
        const baseUrl = `${protocol}//${host}`;

        if (version && !currentUrl.includes(versionValidationPath)) {
          // Offer new version URL for browsers to detect new version
          window.location.replace(`${baseUrl}${versionValidationPath}`);
        }
      } catch (error) {
        console.log('Continue with cached version..');
      }
    }).then(() => {
      return getAndAllDocs()
    })
    .then((allDocs: any) => {
        setProjectsData(allDocs.project);
        setWorkHistory(allDocs.history);
        setEducation(allDocs.education);
        setCompetence(allDocs.competence);
        setMainDescription(allDocs.bio);
        setAwards(allDocs.award);
        setCertificates(allDocs.certificate);
      })
    }, []);
  
  return (
    <div className="App">
      <Main workHistory={workHistory} education={education} competence={competence} bio={mainDescription} projects={projectsData} awards={awards} certificates={certificates}/>
    </div>
  );
}

export default App;
