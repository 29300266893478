import '../styles/hexagon.scss';
import IconButton from '@mui/material/IconButton';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';

interface HexagonProps {
  name?: string
}

export default function Container(props: HexagonProps) {
  return(
    <div className="hexagon-container">
       <div className="hexagon">
          <div className="hexagon-inner">
              <IconButton disabled={true}>
                <EmojiEventsTwoToneIcon color="primary" fontSize="large"></EmojiEventsTwoToneIcon>
              </IconButton>
            <div>
              <p className="hex-text-box">{props.name}</p>
            </div>
          </div>
      </div>
    </div>
  );
}