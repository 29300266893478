import { getViewPortHeight } from './layout';
import { scroller } from 'react-scroll';

const scrollToTarget = (page: number) => {
  window.scrollTo(
    {
      top: Math.round(page * getViewPortHeight()),
      left: 0,
      behavior: 'smooth'
    }
  )
}

const scrollToElement = (DOMelement: string, duration: number = 1000, delay: number = 0) => {
  scroller.scrollTo(
    DOMelement,
    {
      duration,
      delay,
      smooth: true
    }
  );
}

export {
  scrollToElement,
  scrollToTarget
};