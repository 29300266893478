import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { scrollToElement } from '../utils/scrollToElement';

interface ListItemProps {
  name: string;
  action: () => any;
}

function ListItemWrapper(props: ListItemProps) {
  return (
    <ListItem onClick={() => {
      props.action();
      scrollToElement(props.name);
    }}>
      <Button color="inherit">{props.name}</Button>
    </ListItem>
  )
}

interface NestedListProps {
  name: string;
  action: () => any;
  menuItems: string[];
}

function NestedList(props: NestedListProps) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  return (
    <div>
      <ListItem onClick={() => {
        handleClick()
      }}>
        <Button  color="inherit">{props.name}
          {subMenuOpen ? <ExpandLess style={{marginLeft: '10px'}} /> : <ExpandMore style={{marginLeft: '10px'}} />}
        </Button>
      </ListItem>
      <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
        <List sx={{ pl: 2 }}>
          {props.menuItems.map((item: string, index: number) => {
            return <ListItemWrapper key={index} name={item} action={props.action}/>
          })}
        </List>
      </Collapse>
    </div>
  )
}

export default function ButtonAppBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{ background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
          <Button
            color="inherit"
            onClick={() => { scrollToElement("Home"); }}>
              Portfolio
          </Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, backgroundColor: 'rgba(100, 100, 100, 0.5)' }}
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <SwipeableDrawer anchor="right" open={menuOpen} onOpen={() => setMenuOpen(true)} onClose={() => setMenuOpen(false)}>
          <IconButton onClick={() => setMenuOpen(false)}>
            <ChevronRightIcon></ChevronRightIcon>
          </IconButton>
          <hr />
          <List>
            <ListItemWrapper name='Home' action={() => setMenuOpen(false)}/>
            <ListItemWrapper name='Intro' action={() => setMenuOpen(false)}/>
            <NestedList name='Competence' action={() => setMenuOpen(false)} menuItems={['Certificates', 'Awards', 'Work History', 'Education', 'Programming', 'Frameworks', 'Tools']}/>
            <ListItemWrapper name='Projects' action={() => setMenuOpen(false)}/>
          </List>
        </SwipeableDrawer>
      </AppBar>
    </Box>
  );
}
