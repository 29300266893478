import Intro from './intro';
import Navbar from './navbar';
import { Parallax } from 'react-parallax';
import parallaxImage from '../images/forest-bg.jpg';
import personalPhoto from '../images/mypic-cropped.jpg';
import { getPreferedListHeight, getViewPortHeight } from '../utils/layout';
import DotTimeLine from './dot-timeline';
import Projects from './projects';
import GeneralList from './general-list';
import ImageLink from './image-link';
import Hexagon from './hexagon';
import { sortByKey } from '../utils/sortings';
import { Element } from 'react-scroll';
import { Award, Bio, Certificate, Competence, Education, Project, WorkHistory } from '../types/backend';

interface MainProps {
  workHistory: WorkHistory[],
  education: Education[],
  competence: Competence[],
  bio: Bio[],
  projects: Project[],
  awards: Award[],
  certificates: Certificate[]
}

function Main(props: MainProps) {
  const listHeight = getPreferedListHeight(600);
  const dotTimelineDynamics = {
    overscrollBehavior: 'auto',
    overflow: 'auto',
    maxHeight: listHeight
  }
  const education = () => {
    return(
      <div className="full-block l-blue">
        <div className="wrapper l-blue">
          <div className="half-block">
            <Element name='Work History' />
            <div className="left-margin">
              <h2 className='custom-h2 dark-bg-color white-text-border'>Work History</h2>
              <hr />
              <div style={dotTimelineDynamics} className='dot-timeline-box'>
                <DotTimeLine items={props.workHistory}/>
              </div>
            </div>
          </div>
          <div className="half-block">
            <Element name='Education' />
            <div className="right-margin">
              <h2 className='custom-h2 dark-bg-color white-text-border'>Education</h2>
              <hr />
              <div style={dotTimelineDynamics} className='dot-timeline-box'>
                <DotTimeLine items={props.education}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const awards = () => {
    return props.awards.map(award => {
      return <Hexagon name={award.name} key={award.name} />
    });
  }

  const certificates = () => {
    return props.certificates.map(certificate => {
      return <ImageLink key={certificate.name} url={certificate.verification_url} picture={certificate.image_url}/>
    })
  }

  const languages = 'programming & markup language';
  const frameworks = 'framework';
  
  return (
    <div className="App">
      <Navbar />
      <Element name='Home'></Element>
      <Parallax
            bgImage={parallaxImage}
            strength={300}
            key={'parallax-image'}
            style={{zIndex: 1}}>
        <div style={{height: getViewPortHeight(), width: '100%'}}>
          <Intro />
        </div>
      </Parallax>
      <div className="bg-to-d-gradient" />
      <div className="wrapper l-blue">
        <Element name='Intro' />
        <div className="centralized-block wrapper" style={{textAlign:'left'}}>
            <div className='third-block'>
              <img alt="profile-icon" className="photo" src={personalPhoto} onClick={() => {}} />
            </div>
            <div className='full-block' style={{margin:'auto'}}>
              <p className="basic-text" style={{textAlign:'left'}}>{props.bio[0] && props.bio[0].description}</p>
            </div>
        </div>
      </div>
      <div className="bg-to-l-gradient" />
      <div className="full-block d-blue">
        <h1 className='custom-h1 black-text-border'> Competence </h1>
        <hr />
        <div className="wrapper d-blue">
          <Element name='Certificates' />
          <div className="half-block">
            <div className="left-margin">
              <h2 className='custom-h2 black-text-border'>Certificates</h2>
              <hr />
              {certificates()}
            </div>
          </div>
          <div className="half-block">
            <Element name='Awards' />
            <div className="right-margin">
              <h2 className='custom-h2 black-text-border'>Awards</h2>
              <hr />
              {awards()}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-to-d-gradient" />
      {education()}
      <div className="bg-to-l-gradient" />
      <div className="full-block d-blue">
        <div className="wrapper">
          <div className="centralized-block wrapper">
            <div className="third-block">
              <Element name='Programming' />
              <h2 className='custom-h2 black-text-border'>Programming</h2>
              <hr />
              <div className='general-list-margin' style={{overscrollBehavior: 'auto'}}>
                <GeneralList items={props.competence.filter(item => item.category === languages).sort(sortByKey('experience'))}/>            
              </div>
            </div>
            <div className="third-block">
              <Element name='Frameworks' />
              <h2 className='custom-h2 black-text-border'>Frameworks</h2>
              <hr />
              <div className='general-list-margin' style={{overscrollBehavior: 'auto'}}>
                <GeneralList items={props.competence.filter(item => item.category === frameworks).sort(sortByKey('experience'))}/>
              </div>
            </div>
            <div className="third-block">
              <Element name='Tools' />
              <h2 className='custom-h2 black-text-border'>Tools</h2>
              <hr />
              <div className='general-list-margin' style={{overscrollBehavior: 'auto'}}>
                <GeneralList items={props.competence.filter(item => item.category !== frameworks).filter(item => item.category !== languages).sort(sortByKey('experience'))}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-to-d-gradient" />
      <div className="l-blue">
      <Element name='Projects' />
        <div className="centralized-block">
          <h1 className='custom-h1 dark-bg-color white-text-border'>Projects</h1>
          <hr />
          <Projects items={props.projects}/>
        </div>
      </div>
    </div>
  );
} 
export default Main;
