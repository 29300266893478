import '../styles/intro.scss';

function Intro() {
  return (
    <div className='intro' id='intro' style={{color: '#eee'}}>
      <div className='intro-inner-box'>
        <h1>Olli Nissinen</h1>
        <h2>Programmer ready for challenges!</h2>
      </div>
    </div>
  );
};

export default Intro;

