interface ImageLinkProps {
  url: string;
  picture: string;
}

export default function ImageLink(props: ImageLinkProps) {
  return (
    <a href={props.url} target="_blank" rel="noreferrer">
      <img className='certificate' alt='certificate_image' src={props.picture} />
    </a>
  );
}