function getViewPortHeight() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

function getViewPortWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function getPreferedListHeight(maxHeight: number) {
  const halfViewPortHeight = getViewPortHeight() / 2;
  return halfViewPortHeight > maxHeight ? maxHeight : halfViewPortHeight;
}

export {
  getViewPortHeight,
  getViewPortWidth,
  getPreferedListHeight
}